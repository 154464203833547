import React, { useState } from 'react';
import GitHubIntegration from './GitHubIntegration';
import PreviewDashboard from './PreviewDashboard';
import { Boxes, Play } from 'lucide-react';

const DeploymentManager = () => {
  const [activeTab, setActiveTab] = useState('repositories');

  return (
    <div className="space-y-6">
      <div className="flex space-x-4 mb-6">
        <button
          onClick={() => setActiveTab('repositories')}
          className={`px-4 py-2 rounded-lg flex items-center ${
            activeTab === 'repositories' 
              ? 'bg-blue-500 text-white' 
              : 'bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-300'
          }`}
        >
          <Boxes className="w-4 h-4 mr-2" />
          Repositories
        </button>
        <button
          onClick={() => setActiveTab('previews')}
          className={`px-4 py-2 rounded-lg flex items-center ${
            activeTab === 'previews' 
              ? 'bg-blue-500 text-white' 
              : 'bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-300'
          }`}
        >
          <Play className="w-4 h-4 mr-2" />
          Previews
        </button>
      </div>

      <div className="mt-6">
        {activeTab === 'repositories' ? <GitHubIntegration /> : <PreviewDashboard />}
      </div>
    </div>
  );
};

export default DeploymentManager;
