import React, { useState, useEffect } from 'react';
import { 
  Globe, 
  Trash2, 
  RefreshCw, 
  CheckCircle2, 
  XCircle,
  Clock
} from 'lucide-react';

const PreviewDashboard = () => {
  const [previews, setPreviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchPreviews();
    const interval = setInterval(fetchPreviews, 10000);
    return () => clearInterval(interval);
  }, []);

  const fetchPreviews = async () => {
    try {
      setLoading(true);
      const response = await fetch('http://localhost:3001/api/deployments/previews');
      const data = await response.json();
      setPreviews(data);
    } catch (error) {
      setError('Failed to load previews');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (previewId) => {
    try {
      await fetch(`http://localhost:3001/api/deployments/preview/${previewId}`, {
        method: 'DELETE'
      });
      fetchPreviews();
    } catch (error) {
      console.error('Error deleting preview:', error);
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'running':
        return <CheckCircle2 className="w-5 h-5 text-green-500" />;
      case 'exited':
        return <XCircle className="w-5 h-5 text-red-500" />;
      default:
        return <Clock className="w-5 h-5 text-yellow-500" />;
    }
  };

  if (loading && previews.length === 0) {
    return (
      <div className="flex items-center justify-center p-8">
        <RefreshCw className="w-6 h-6 animate-spin text-blue-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 bg-red-50 dark:bg-red-900/20 text-red-600 dark:text-red-400 rounded-lg">
        {error}
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h2 className="text-2xl font-semibold text-gray-900 dark:text-white">Active Previews</h2>
        <button
          onClick={fetchPreviews}
          className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg"
        >
          <RefreshCw className="w-4 h-4" />
        </button>
      </div>

      {previews.length === 0 ? (
        <div className="text-center py-12">
          <Globe className="w-12 h-12 mx-auto text-gray-400 mb-4" />
          <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2">
            No Active Previews
          </h3>
          <p className="text-gray-500 dark:text-gray-400">
            Deploy a repository to see previews here
          </p>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {previews.map((preview) => (
            <div
              key={preview.id}
              className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-sm hover:shadow-md transition-all"
            >
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center">
                  {getStatusIcon(preview.status)}
                  <h3 className="ml-2 font-medium text-gray-900 dark:text-white">
                    {preview.id}
                  </h3>
                </div>
                <button
                  onClick={() => handleDelete(preview.id)}
                  className="p-1 text-gray-400 hover:text-red-500 rounded"
                >
                  <Trash2 className="w-5 h-5" />
                </button>
              </div>

              <div className="space-y-2">
                <div className="text-sm text-gray-500 dark:text-gray-400">
                  Created: {new Date(preview.created * 1000).toLocaleString()}
                </div>
                <div className="flex justify-between items-center">
                  <a
                    href={preview.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:text-blue-600 text-sm flex items-center"
                  >
                    <Globe className="w-4 h-4 mr-1" />
                    Open Preview
                  </a>
                  <span className={`px-2 py-1 text-xs rounded-full ${
                    preview.status === 'running'
                      ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300'
                      : 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300'
                  }`}>
                    {preview.status}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PreviewDashboard;
