import React from 'react';
import { Clock, GitCommit, Download, Terminal, FolderTree, Server } from 'lucide-react';

const ChangelogView = () => {
  const changes = [
    {
      date: '2023-11-06',
      type: 'setup',
      title: 'Initial System Setup',
      details: [
        'Server-Konfiguration mit Ubuntu 22.04 LTS',
        'Docker & Docker Compose Installation',
        'Traefik v3.0 als Reverse Proxy eingerichtet',
        'Authelia für Authentifizierung implementiert'
      ],
      paths: ['/opt/apps/traefik', '/opt/apps/authelia'],
      commands: [
        'docker-compose up -d',
        'docker network create proxy'
      ]
    },
    {
      date: '2023-11-06',
      type: 'feature',
      title: 'Dashboard Grundgerüst',
      details: [
        'React Frontend mit Tailwind CSS',
        'Dark/Light Mode System',
        'Express.js Backend API',
        'Docker Container Setup'
      ],
      paths: ['/opt/apps/dashboard/frontend', '/opt/apps/dashboard/backend'],
      commands: [
        'cd /opt/apps/dashboard',
        'docker-compose up -d'
      ]
    },
    {
      date: '2023-11-06',
      type: 'feature',
      title: 'System Monitoring',
      details: [
        'Server Metriken Implementation',
        'Container Status Überwachung',
        'Resource Usage Tracking',
        'Live Updates alle 5 Sekunden'
      ],
      paths: ['/opt/apps/dashboard/backend/server.js'],
      apis: ['/metrics', '/status']
    },
    {
      date: '2023-11-06',
      type: 'feature',
      title: 'Bolt.new Integration',
      details: [
        'Bolt.new Container Deployment',
        'Traefik Routing Konfiguration',
        'Domain Setup: bolt.owmedia.ai'
      ],
      paths: ['/opt/apps/bolt'],
      commands: [
        'docker-compose up -d',
        'docker logs bolt'
      ]
    },
    {
      date: '2023-11-07',
      type: 'feature',
      title: 'Deployment System',
      details: [
        'Preview Service Backend',
        'Template System Struktur',
        'Multi-Framework Support:',
        '- React (Vite & CRA)',
        '- Vue.js',
        '- Svelte',
        '- Node.js',
        '- Next.js'
      ],
      paths: [
        '/opt/apps/deployment/templates',
        '/opt/apps/deployment/previews',
        '/opt/apps/dashboard/backend/services/deployments'
      ],
      status: 'in-progress'
    }
  ];

  const exportChangelog = () => {
    const markdown = changes.map(change => `
# ${change.title}
Date: ${change.date}
Type: ${change.type}

## Details
${change.details.map(d => `- ${d}`).join('\n')}

## Paths
${change.paths.map(p => `- \`${p}\``).join('\n')}

${change.commands ? `## Commands\n${change.commands.map(c => `\`\`\`bash\n${c}\n\`\`\``).join('\n')}` : ''}
${change.apis ? `## API Endpoints\n${change.apis.map(a => `- \`${a}\``).join('\n')}` : ''}
${change.status ? `\nStatus: ${change.status}` : ''}
`).join('\n---\n');

    const blob = new Blob([markdown], { type: 'text/markdown' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'system-changelog.md';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">System Changelog</h1>
        <button
          onClick={exportChangelog}
          className="flex items-center px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
        >
          <Download className="w-4 h-4 mr-2" />
          Export as Markdown
        </button>
      </div>
      
      <div className="space-y-8">
        {changes.map((change, index) => (
          <div key={index} className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-6">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center space-x-3">
                <GitCommit className="text-blue-500" />
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                  {change.title}
                </h2>
                {change.status && (
                  <span className={`px-3 py-1 rounded-full text-sm ${
                    change.status === 'in-progress' 
                      ? 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300'
                      : 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300'
                  }`}>
                    {change.status}
                  </span>
                )}
              </div>
              <div className="flex items-center text-gray-500 dark:text-gray-400">
                <Clock className="w-4 h-4 mr-2" />
                {change.date}
              </div>
            </div>
            
            <div className="space-y-4">
              <ul className="list-disc list-inside space-y-2 text-gray-600 dark:text-gray-300">
                {change.details.map((detail, idx) => (
                  <li key={idx}>{detail}</li>
                ))}
              </ul>
              
              {/* Paths Section */}
              {change.paths && (
                <div className="mt-4 space-y-2">
                  <div className="flex items-center text-gray-700 dark:text-gray-300">
                    <FolderTree className="w-4 h-4 mr-2" />
                    <span className="font-semibold">Paths</span>
                  </div>
                  <div className="p-3 bg-gray-50 dark:bg-gray-700 rounded-lg">
                    {change.paths.map((path, idx) => (
                      <p key={idx} className="text-sm font-mono text-gray-600 dark:text-gray-300">
                        {path}
                      </p>
                    ))}
                  </div>
                </div>
              )}
              
              {/* Commands Section */}
              {change.commands && (
                <div className="mt-4 space-y-2">
                  <div className="flex items-center text-gray-700 dark:text-gray-300">
                    <Terminal className="w-4 h-4 mr-2" />
                    <span className="font-semibold">Commands</span>
                  </div>
                  <div className="p-3 bg-gray-50 dark:bg-gray-700 rounded-lg">
                    {change.commands.map((cmd, idx) => (
                      <p key={idx} className="text-sm font-mono text-gray-600 dark:text-gray-300">
                        $ {cmd}
                      </p>
                    ))}
                  </div>
                </div>
              )}

              {/* API Endpoints Section */}
              {change.apis && (
                <div className="mt-4 space-y-2">
                  <div className="flex items-center text-gray-700 dark:text-gray-300">
                    <Server className="w-4 h-4 mr-2" />
                    <span className="font-semibold">API Endpoints</span>
                  </div>
                  <div className="p-3 bg-gray-50 dark:bg-gray-700 rounded-lg">
                    {change.apis.map((api, idx) => (
                      <p key={idx} className="text-sm font-mono text-gray-600 dark:text-gray-300">
                        {api}
                      </p>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChangelogView;
