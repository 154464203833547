import React, { useState, useEffect } from 'react';
import { 
  LayoutDashboard, 
  Boxes, 
  Activity, 
  Settings, 
  Sun, 
  Moon, 
  ChevronLeft, 
  ChevronRight,
  History,
  Gauge,
  HardDrive,
  Cpu,
  BarChart
} from 'lucide-react';

// Korrekte Imports für alle existierenden Komponenten
import ChangelogView from './components/changelog/ChangelogView';
import { DeploymentManager } from './components/deployments';
import GitHubIntegration from './components/deployments/GitHubIntegration';
import PreviewDashboard from './components/deployments/PreviewDashboard';
import TemplateGallery from './components/deployments/TemplateGallery';

function App() {
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [activeMenu, setActiveMenu] = useState(() => {
    const path = window.location.pathname.slice(1) || 'overview';
    return path;
  });

  useEffect(() => {
    document.documentElement.classList.toggle('dark', theme === 'dark');
    localStorage.setItem('theme', theme);
  }, [theme]);

  useEffect(() => {
    const newPath = activeMenu === 'overview' ? '/' : `/${activeMenu}`;
    window.history.pushState({}, '', newPath);
  }, [activeMenu]);

  const menuItems = [
    { id: 'overview', icon: LayoutDashboard, label: 'Overview', color: '#4F46E5' },
    { id: 'applications', icon: Boxes, label: 'Applications', color: '#059669' },
    { id: 'monitoring', icon: Activity, label: 'Monitoring', color: '#DC2626' },
    { id: 'changelog', icon: History, label: 'Changelog', color: '#F59E0B' },
    { id: 'settings', icon: Settings, label: 'Settings', color: '#9333EA' }
  ];

  const renderMainContent = () => {
    switch(activeMenu) {
      case 'applications':
        return <DeploymentManager />;
      case 'changelog':
        return <ChangelogView />;
      case 'overview':
        return (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {/* Server Status Card */}
            <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm p-6">
              <div className="flex items-center mb-6">
                <Cpu size={28} className="text-indigo-600 dark:text-indigo-400" />
                <h2 className="text-xl font-semibold ml-3 text-gray-800 dark:text-white">
                  Server Status
                </h2>
              </div>
              <div className="space-y-6">
                {[
                  { label: 'CPU Usage', value: 45, icon: Cpu, color: 'from-indigo-500 to-blue-500' },
                  { label: 'Memory', value: 60, color: 'from-emerald-500 to-green-500' },
                  { label: 'Storage', value: 28, icon: HardDrive, color: 'from-purple-500 to-pink-500' }
                ].map((metric) => (
                  <div key={metric.label} className="group">
                    <div className="flex justify-between mb-2">
                      <div className="flex items-center">
                        {metric.icon && <metric.icon size={18} className="text-gray-400 mr-2" />}
                        <span className="text-gray-600 dark:text-gray-300">{metric.label}</span>
                      </div>
                      <span className="font-semibold text-gray-800 dark:text-white">{metric.value}%</span>
                    </div>
                    <div className="h-2 bg-gray-100 dark:bg-gray-700 rounded-full overflow-hidden">
                      <div 
                        className={`h-full bg-gradient-to-r ${metric.color} rounded-full transition-all duration-500 ease-out group-hover:opacity-80`}
                        style={{ width: `${metric.value}%` }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Active Applications Card */}
            <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm p-6">
              <div className="flex items-center mb-6">
                <Boxes size={28} className="text-emerald-600 dark:text-emerald-400" />
                <h2 className="text-xl font-semibold ml-3 text-gray-800 dark:text-white">Active Applications</h2>
              </div>
              <div className="space-y-4">
                {[
                  { name: 'Bolt App', status: 'Running', color: 'bg-emerald-100 text-emerald-700 dark:bg-emerald-900 dark:text-emerald-300' },
                  { name: 'API Server', status: 'Running', color: 'bg-emerald-100 text-emerald-700 dark:bg-emerald-900 dark:text-emerald-300' },
                  { name: 'ML Service', status: 'Updating', color: 'bg-amber-100 text-amber-700 dark:bg-amber-900 dark:text-amber-300' }
                ].map((app) => (
                  <div key={app.name} className="flex items-center justify-between p-3 bg-gray-50 dark:bg-gray-700 rounded-lg">
                    <span className="font-medium text-gray-700 dark:text-gray-200">{app.name}</span>
                    <span className={`px-3 py-1 rounded-full text-sm font-medium ${app.color}`}>
                      {app.status}
                    </span>
                  </div>
                ))}
              </div>
            </div>

            {/* Quick Actions Card */}
            <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm p-6">
              <div className="flex items-center mb-6">
                <BarChart size={28} className="text-red-600 dark:text-red-400" />
                <h2 className="text-xl font-semibold ml-3 text-gray-800 dark:text-white">Quick Actions</h2>
              </div>
              <div className="space-y-4">
                <button className="w-full py-3 bg-indigo-600 hover:bg-indigo-700 text-white rounded-lg font-medium transition-colors">
                  Deploy New App
                </button>
                <button className="w-full py-3 border-2 border-gray-200 dark:border-gray-600 hover:border-gray-300 dark:hover:border-gray-500 rounded-lg font-medium transition-colors dark:text-white">
                  View Logs
                </button>
                <button className="w-full py-3 border-2 border-gray-200 dark:border-gray-600 hover:border-gray-300 dark:hover:border-gray-500 rounded-lg font-medium transition-colors dark:text-white">
                  Backup System
                </button>
              </div>
            </div>
          </div>
        );
      case 'monitoring':
        return (
          <div className="text-gray-600 dark:text-gray-300">
            Monitoring features coming soon...
          </div>
        );
      case 'settings':
        return (
          <div className="text-gray-600 dark:text-gray-300">
            Settings panel coming soon...
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 transition-colors duration-300">
      {/* Sidebar */}
      <div className={`fixed top-0 left-0 h-full bg-white dark:bg-gray-800 shadow-lg transition-all duration-300 ${
        sidebarCollapsed ? 'w-20' : 'w-64'
      }`}>
        <div className="flex items-center justify-between p-4 border-b border-gray-100 dark:border-gray-700">
          {!sidebarCollapsed && (
            <span className="text-xl font-bold text-gray-800 dark:text-white">DevOps Hub</span>
          )}
          <div className="flex gap-2">
            <button 
              onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')}
              className="p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
            >
              {theme === 'dark' ? 
                <Sun className="text-yellow-500" size={20} /> : 
                <Moon className="text-blue-500" size={20} />
              }
            </button>
            <button 
              onClick={() => setSidebarCollapsed(!sidebarCollapsed)}
              className="p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
            >
              {sidebarCollapsed ? 
                <ChevronRight className="text-gray-600 dark:text-gray-300" /> : 
                <ChevronLeft className="text-gray-600 dark:text-gray-300" />
              }
            </button>
          </div>
        </div>

        <div className="p-3">
          {menuItems.map((item) => (
            <button
              key={item.id}
              onClick={() => setActiveMenu(item.id)}
              className={`w-full flex items-center p-3 rounded-xl mb-2 transition-all ${
                activeMenu === item.id 
                  ? 'bg-indigo-50 dark:bg-gray-700 shadow-sm' 
                  : 'hover:bg-gray-50 dark:hover:bg-gray-700'
              }`}
            >
              <item.icon 
                size={24} 
                style={{ color: item.color }}
                className="transition-all"
              />
              {!sidebarCollapsed && (
                <span className="ml-3 font-medium text-gray-700 dark:text-gray-200">{item.label}</span>
              )}
            </button>
          ))}
        </div>
      </div>

      {/* Main Content */}
      <div className={`transition-all duration-300 ${
        sidebarCollapsed ? 'ml-20' : 'ml-64'
      } p-8`}>
        {renderMainContent()}
      </div>
    </div>
  );
}

export default App;
