import React, { useState, useEffect } from 'react';
import { Globe, RefreshCw, GitBranch, Github } from 'lucide-react';
import TemplateGallery from './TemplateGallery';

const GitHubIntegration = () => {
 const [repositories, setRepositories] = useState([]);
 const [loading, setLoading] = useState(true);
 const [error, setError] = useState(null);
 const [selectedRepo, setSelectedRepo] = useState(null);

 useEffect(() => {
   fetchRepositories();
 }, []);

 const fetchRepositories = async () => {
   try {
     setLoading(true);
     const response = await fetch('/github/repos');
     const data = await response.json();
     setRepositories(data);
   } catch (error) {
     setError('Failed to fetch repositories');
   } finally {
     setLoading(false);
   }
 };

 const handleDeploy = async (repo) => {
   setSelectedRepo(repo);
 };

 if (selectedRepo) {
   return <TemplateGallery repo={selectedRepo} onBack={() => setSelectedRepo(null)} />;
 }

 if (loading && repositories.length === 0) {
   return (
     <div className="flex items-center justify-center p-8">
       <RefreshCw className="w-6 h-6 animate-spin text-blue-500" />
     </div>
   );
 }

 if (error) {
   return (
     <div className="bg-red-50 dark:bg-red-900/20 text-red-600 dark:text-red-400 p-4 rounded-lg">
       {error}
     </div>
   );
 }

 return (
   <div className="space-y-6">
     <div className="flex items-center justify-between">
       <h2 className="text-2xl font-semibold text-gray-900 dark:text-white">
         GitHub Repositories
       </h2>
       <button
         onClick={fetchRepositories}
         className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg"
       >
         <RefreshCw className="w-4 h-4" />
       </button>
     </div>

     <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
       {repositories.map((repo) => (
         <div
           key={repo.id}
           className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-sm hover:shadow-md transition-all"
         >
           <div className="flex items-center mb-4">
             <Github className="w-6 h-6 text-gray-600 dark:text-gray-400" />
             <div className="ml-3">
               <h3 className="font-medium text-gray-900 dark:text-white">
                 {repo.name}
               </h3>
               <p className="text-sm text-gray-500 dark:text-gray-400">
                 {repo.description || 'No description'}
               </p>
             </div>
           </div>

           <div className="flex items-center text-sm text-gray-500 dark:text-gray-400 mb-4">
             <GitBranch className="w-4 h-4 mr-1" />
             {repo.defaultBranch}
           </div>

           <div className="flex items-center justify-between mt-4">
             <a
               href={repo.url}
               target="_blank"
               rel="noopener noreferrer"
               className="text-blue-500 hover:text-blue-600 text-sm flex items-center"
             >
               <Globe className="w-4 h-4 mr-1" />
               View Repository
             </a>
             <button
               onClick={() => handleDeploy(repo)}
               className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg transition-colors text-sm"
             >
               Deploy
             </button>
           </div>
         </div>
       ))}
     </div>
   </div>
 );
};

export default GitHubIntegration;
