import React from 'react';
import { Code, Box, Globe, Rocket, ChevronLeft } from 'lucide-react';

const TemplateGallery = ({ repo, onBack }) => {
 const templates = [
   {
     id: 'react-vite',
     name: 'React + Vite',
     description: 'Modern React setup with Vite bundler',
     icon: <Code className="w-8 h-8 text-blue-500" />,
     features: ['Fast Refresh', 'TypeScript', 'Tailwind']
   },
   {
     id: 'react-cra',
     name: 'React (CRA)',
     description: 'Classic React setup with Create React App',
     icon: <Box className="w-8 h-8 text-blue-600" />,
     features: ['Production Ready', 'Well Tested', 'PWA Support']
   },
   {
     id: 'vue',
     name: 'Vue.js',
     description: 'Vue 3 with Vite',
     icon: <Globe className="w-8 h-8 text-green-500" />,
     features: ['Composition API', 'SFC', 'TypeScript']
   },
   {
     id: 'sveltekit',
     name: 'SvelteKit',
     description: 'Svelte with SvelteKit',
     icon: <Rocket className="w-8 h-8 text-orange-500" />,
     features: ['SSR', 'File-based Routing', 'API Routes']
   }
 ];

 const handleTemplateSelect = async (template) => {
   try {
     const response = await fetch('/api/deployments/preview', {
       method: 'POST',
       headers: {
         'Content-Type': 'application/json'
       },
       body: JSON.stringify({
         repoId: repo.id,
         templateId: template.id
       })
     });

     const data = await response.json();
     if (data.success) {
       onBack(); // Zurück zur Repository-Liste
     }
   } catch (error) {
     console.error('Error deploying:', error);
   }
 };

 return (
   <div>
     <div className="flex items-center mb-6">
       <button
         onClick={onBack}
         className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg mr-4"
       >
         <ChevronLeft className="w-5 h-5" />
       </button>
       <div>
         <h2 className="text-2xl font-semibold text-gray-900 dark:text-white">
           Choose Template for {repo.name}
         </h2>
         <p className="text-gray-500 dark:text-gray-400 text-sm mt-1">
           Select a template to deploy your application
         </p>
       </div>
     </div>

     <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
       {templates.map((template) => (
         <div 
           key={template.id}
           onClick={() => handleTemplateSelect(template)}
           className="bg-white dark:bg-gray-800 rounded-lg p-6 hover:shadow-lg transition-all cursor-pointer border border-gray-200 dark:border-gray-700"
         >
           <div className="flex items-center mb-4">
             {template.icon}
             <div className="ml-4">
               <h3 className="text-gray-900 dark:text-white font-medium">
                 {template.name}
               </h3>
               <p className="text-gray-500 dark:text-gray-400 text-sm">
                 {template.description}
               </p>
             </div>
           </div>
           
           <div className="flex flex-wrap gap-2 mt-4">
             {template.features?.map((feature) => (
               <span 
                 key={feature}
                 className="px-2 py-1 bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-100 text-xs rounded"
               >
                 {feature}
               </span>
             ))}
           </div>
         </div>
       ))}
     </div>
   </div>
 );
};

export default TemplateGallery;
